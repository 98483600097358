



















































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// eslint-disable-next-line
// @ts-ignore
import { FullBgCard, InfoAreas } from '@/client/components/_theme';
import RestStatus from '@/client/components/core/RestStatus.vue';
import Alert from '@/client/components/core/Alert.vue';

@Component({
  components: {
    FullBgCard,
    InfoAreas,
    RestStatus,
    Alert,
  },
})
export default class Cost extends Vue {
  name = '';
  email = '';
  message = '';
  boolean = false;
  error = '';
  responseType = '';
  alertMessage = '';
  showAlert = '';

  cardAbout: any = {
    image1: '/red_door_2.png',
    image2: '/red_door_3.png',
  };

  // eslint-disable-next-line class-methods-use-this
  sectionBackground(image: string) {
    return {
      backgroundImage: `url(${image})`,
    };
  }
}
