import Vue from 'vue';
import router from './router';
import store from './client/store';
import App from './App.vue';

import Theme from './client/_theme';

Vue.use(Theme);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
