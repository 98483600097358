










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RestStatus extends Vue {
  @Prop({ required: true })
  readonly type!: string;

  @Prop({ required: true })
  readonly message!: string;

  @Prop({ required: true })
  readonly show!: boolean;

  // eslint-disable-next-line class-methods-use-this
  removeNotify() {
    this.$emit('reset');
  }
}
