





































































/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import { MobileMenu } from '@/client/components/_theme';

let resizeTimeout: any = 0;
function resizeThrottler(actualResizeHandler: any) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

@Component({
  components: {
    MobileMenu,
  },
})
export default class MainNavbar extends Vue {
  @Prop({ default: 'white ' })
  readonly type!: string;

  @Prop({ default: 0 })
  readonly colorOnScroll!: number;

  @Prop({ default: true })
  readonly navbarTransparent!: boolean;

  /* eslint-disable */
  docs_link = '/';

  extraNavClasses = '';

  toggledClass = false;

  brand = 'OptoSearch';

  mounted() {
    document.addEventListener('scroll', this.scrollListener);
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollListener);
  }

  bodyClick() {
    let bodyClick = document.getElementById('bodyClick');

    if (bodyClick === null) {
      const body = document.querySelector('body');
      const elem = document.createElement('div');
      elem.setAttribute('id', 'bodyClick');

      if (body) body.appendChild(elem);

      bodyClick = document.getElementById('bodyClick');

      if (bodyClick) bodyClick.addEventListener('click', this.toggleNavbarMobile);
    } else {
      bodyClick.remove();
    }
  }

  toggleNavbarMobile() {
    // @ts-ignore
    this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
    // @ts-ignore
    this.toggledClass = !this.toggledClass;
    this.bodyClick();
  }

  handleScroll() {
    const scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
    const navbarColor = document.getElementById('toolbar');
    // @ts-ignore
    this.currentScrollValue = scrollValue;
    if (scrollValue > 200) {
      // @ts-ignore
      this.extraNavClasses = `md-${this.type}`;
      // @ts-ignore
      navbarColor.classList.remove('md-transparent');
    }

    // @ts-ignore
    else if (this.extraNavClasses) {
      // @ts-ignore
      this.extraNavClasses = '';
      // @ts-ignore
      navbarColor.classList.add('md-transparent');
    }
  }

  scrollListener() {
    resizeThrottler(this.handleScroll);
  }
}
