






























































































































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import RestStatus from '@/client/components/core/RestStatus.vue';
import Alert from '@/client/components/core/Alert.vue';
import api from '../util/api';
import {
  ProfileCard,
  InfoAreas,
  Parallax,
  FullBgCard,
  // @ts-ignore
} from './_theme';

@Component({
  components: {
    Alert,
    ProfileCard,
    InfoAreas,
    Parallax,
    FullBgCard,
    RestStatus,
  },
})
export default class MainNavbar extends Vue {
  image: string = '/solar-panel-demo.jpeg';
  name: string = '';
  email: string = '';
  message: string = '';
  boolean: boolean = false;
  loading: boolean = false;
  error: boolean = false;
  responseType: string = '';
  alertMessage: string = '';
  showAlert: boolean = false;
  cardAbout: any = {
    image1: '/red_door_3.png',
    image2: '/red_door_2.png',
  }

  resetForm() {
    this.message = '';
    this.name = '';
    this.email = '';
    this.boolean = false;
  }

  resetAlert() {
    this.alertMessage = '';
    this.responseType = '';
    this.showAlert = false;
  }

  popAlert(response: any) {
    this.responseType = response.status === 200 ? 'success' : 'danger';
    this.alertMessage = response.data;
    this.showAlert = true;
  }

  submitLead() {
    this.resetAlert();
    this.loading = true;

    const lead = {
      name: this.name,
      email: this.email,
      message: this.message,
    };

    api('POST', 'https://rarequaticfinds.herokuapp.com/api/leads/question', lead)
      .then((response: any) => {
        this.loading = false;
        this.resetForm();
        this.popAlert(response);
      })
      .catch(() => {
        const response = {
          status: 500,
          data: 'Unable to submit, maybe this is a duplicate?',
        };

        this.popAlert(response);
      });
  }

  // eslint-disable-next-line
  sectionBackground(image: string) {
    return {
      backgroundImage: `url(${image})`,
    };
  }
}
