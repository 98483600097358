





import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RestStatus extends Vue {
  @Prop({ default: false })
  readonly isLoading!: boolean;

  @Prop({ default: false })
  readonly hasError!: boolean;
}
