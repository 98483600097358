import Vue from 'vue';
import Router from 'vue-router';
import { parseString } from 'xml2js';
import Landing from './client/components/Landing.vue';
import About from './client/components/About.vue';
import GetStarted from './client/components/GetStarted.vue';
import Terms from './client/components/Terms.vue';
import NotFound from './client/components/NotFound.vue';
import Cost from './client/components/Cost.vue';
import api from './client/util/api';

Vue.use(Router);

const routeArray = [
  {
    path: '/',
    name: 'welcome',
    component: Landing,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/cost',
    name: 'cost',
    component: Cost,
  },
  {
    path: '/get-started',
    name: 'start',
    component: GetStarted,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '*',
    redirect: {
      name: 'not-found',
    },
  },
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior(to, from) {
    return new Promise((resolve: Function) => {
      setTimeout(() => {
        if (to.name !== from.name) {
          resolve(window.scrollTo({ top: 0, behavior: 'smooth' }));
        } else {
          resolve();
        }
      }, 300);
    });
  },
  routes: routeArray,
});

export default router;
